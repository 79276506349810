<template>
  <div id="data-list-thumb-view" @keydown.esc="editingJob = false">
    <transition :name="routerTransition" mode="out-in">
      <div v-if="!editingJob && archivedJobs && archivedJobs.length > 0">
        <vx-card v-for="jobData in archivedJobs" :key="jobData.jobId" class="px-2 py-3 sm:px-5 border-solid border-2 border-gray-200 mb-5">
          <div class="flex items-center justify-between">
            <p class="font-medium text-rag-green truncate text-xl">
              {{ jobData.jobTitle }}
            </p>
            <div class="ml-2 flex-shrink-0 flex">
              <div class="flex items-center">
                <vs-chip v-if="jobData.jobDeactivated" class="mr-1" color="rgb(255,186,0)"> Deactivated </vs-chip>
                <vs-chip class="mr-1" :color="jobData.expired ? 'rgb(255, 71, 87)' : returnChipColor(jobData)">
                  {{ jobData.expired ? 'Expired' : jobData.positionFilled ? 'Filled' : 'Archived' }}
                </vs-chip>
              </div>

              <div>
                <vs-dropdown class="cursor-pointer" :vs-trigger-click="true">
                  <vs-icon size="medium" icon="more_vert"></vs-icon>
                  <vs-dropdown-menu>
                    <vs-dropdown-item
                      v-if="!jobData.jobDeactivated || jobData.jobDeactivated === false"
                      @click="initReactivateJob(jobData)"
                    >
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="move_to_inbox"></vs-icon>
                        <p>Reactivate</p>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="jobData.jobDeactivated === true" :disabled="jobData.submittedForReview ? true : false">
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="task_alt"></vs-icon>
                        <p v-if="!jobData.submittedForReview" @click="initSubmitForReview(jobData)">Submit for review</p>
                        <p v-else>Submitted for review</p>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="initDelete(jobData)">
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="delete"></vs-icon>
                        <p>Delete job</p>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <p class="mt-2 flex text-base items-center text-gray-500 sm:mt-0">
                <feather-icon icon="MapPinIcon" class="text-grey mr-2"></feather-icon>
                {{ jobData.location }}
              </p>
              <p class="mt-2 flex text-base items-center text-gray-500 sm:mt-0 sm:ml-6">
                <feather-icon icon="UserIcon" class="text-grey mr-2"></feather-icon>
                {{ jobData.jobType }}
              </p>
            </div>
            <div class="sm:mt-0">
              <p class="mt-2 flex text-base items-center text-gray-500 sm:mt-0 sm:mr-3">
                <feather-icon icon="CalendarIcon" class="text-grey mr-2"></feather-icon>
                Archived on
                <time :datetime="jobData.created_at">&nbsp;{{ jobData.expire_at | formatDate }}</time>
              </p>
            </div>
          </div>
        </vx-card>
      </div>
      <vs-card
        v-if="archivedJobs && archivedJobs.length === 0"
        class="px-12 py-24 sm:px-24 sm:py-32 rounded-lg bg-white h-10 border-solid border-2 border-gray-200 min-w-full flex-col flex items-center justify-center"
      >
        <div>
          <p class="text-center font-bold text-lg"> You have no Archived Jobs </p>
          <p class="mt-1 text-center text-base text-grey">
            You can archive jobs from the Jobs Page by selecting either 'Position Filled' or 'Archive Job'</p
          >
        </div>
      </vs-card>
      <CreateNewJob
        v-if="editingJob && selected"
        :is-sidebar-active="editingJob"
        :is-archived="true"
        :job-post="selected"
        :edit="editCheck"
        :before-edit="beforeEdit"
        @close-job-creation="cancelEdit"
        @save-archived-job="(job) => reactivateJob(job)"
      />
    </transition>
    <vs-popup title="Are you sure you want to submit this job for review?" :active.sync="submitReviewPopup">
      <div class="vx-row mr-5 ml-0">
        <vs-button
          :disabled="submittingForReview ? true : false"
          color="primary"
          class="vx-col lg:w-1/4 relative"
          @click="submitForReview(selected)"
          >{{ submittingForReview ? 'Submitting...' : 'Yes' }}</vs-button
        >
        <vs-button
          :disabled="submittingForReview ? true : false"
          color="grey"
          class="vx-col lg:w-1/4 ml-3 relative"
          @click="submitReviewPopup = false"
          >Cancel</vs-button
        >
      </div>
    </vs-popup>
    <vs-popup title="Are you sure?" :active.sync="popupActive">
      <p>You are about to delete this archived job.</p>
      <br />
      <p>Once delete, this job cannot be recovered.</p>
      <br />
      <div class="flex">
        <vs-button color="danger" class="vx-col lg:w-1/4" @click="deleteArchivedJob(selected)">Delete</vs-button>
        <vs-button color="grey" class="vx-col lg:w-1/4 ml-3" @click="popupActive = false">Cancel</vs-button>
      </div>
    </vs-popup>
    <vs-popup
      v-if="selected"
      class="overflow-visible"
      title="Are you sure you want to reactivate this job?"
      :active.sync="reactivateJobPopup"
    >
      <div>
        <p
          >To reactivate this job, you will need spend a <strong>{{ selected.jobType }} Job Post Credit</strong>.</p
        >
        <br />
        <p
          >Selecting 'Reactivate Job' will prompt you to edit the job where you can update any relevant information, such as the job's start
          date and end date (if applicable).</p
        >
        <br />
        <p>Once this job is reactivated, it will move back into the Jobs tab and applicants will be able to apply from the Job Board.</p>
      </div>
      <div class="vx-row mr-5 ml-0 mt-5">
        <vs-button icon="archive" color="primary" icon-before class="vx-col lg:w-1/3 relative" @click="editArchivedJob(selected)"
          >Reactivate Job</vs-button
        >
      </div>
    </vs-popup>

    <ArchivedJobsTour />
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js';
import ArchivedJobsTour from '../components/tours/ArchivedJobsTour.vue';
import CreateNewJob from '../components/job-components/CreateNewJob.vue';

export default {
  components: {
    ArchivedJobsTour,
    CreateNewJob,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['sidebar'],
  data() {
    return {
      reactivateJobPopup: false,
      routerTransition: themeConfig.routerTransition || 'none',
      selected: null,
      select: false,
      itemsPerPage: 4,
      modalOpenBanner: false,
      isMounted: false,
      editingJob: false,
      editCheck: false,
      popupActive: false,
      submittingForReview: false,
      submitReviewPopup: false,
    };
  },
  computed: {
    archivedJobs() {
      this.$store.dispatch('archivedJobsModule/setJobList');
      let archivedJobs = this.formatData(this.$store.state.archivedJobsModule.data);
      archivedJobs.sort((x, y) => {
        let timeX = x.expire_at;
        let timeY = y.expire_at;
        return timeY - timeX;
      });
      return archivedJobs;
    },
    profile() {
      return this.$store.getters['employerModule/getUser'];
    },
  },

  destroyed: function () {
    document.removeEventListener('keyup', this.myMethod);
  },
  created() {
    document.addEventListener('keyup', this.myMethod);
    // this.refreshData();
  },

  mounted() {
    this.isMounted = true;
    this.active();
  },
  methods: {
    returnChipColor(jobData) {
      if (jobData.positionFilled) {
        return 'rgb(173, 202, 99)';
      }
    },
    initSubmitForReview(job) {
      this.selected = job;
      this.submitReviewPopup = true;
    },
    async submitForReview(job) {
      let jobData = job;
      jobData.submittedForReview = true;
      this.submittingForReview = true;
      await this.$store.dispatch('forReviewModule/set', { id: jobData.jobId, employerId: jobData.employerId, jobTitle: jobData.jobTitle });
      await this.$store.dispatch('archivedJobsModule/set', jobData);
      this.submittingForReview = false;
      this.submitReviewPopup = false;
      this.$store.dispatch('toggleGeneralPopup', {
        active: true,
        title: 'Review submitted',
        text: 'Thank you, your job has been successfully submitted for review. You will receive a notification once we have take the necessary action.',
        buttonText: 'Okay',
        click: () => {
          this.$store.dispatch('toggleGeneralPopup', { active: false });
        },
      });
    },
    active() {
      if (this.sidebar) {
        this.postJob();
        // eslint-disable-next-line vue/no-mutating-props
        this.sidebar = false;
      }
    },
    creditCheck(job) {
      if (
        (job.jobType === 'Permanent' && this.profile.permanentJobPosts <= 0) ||
        (job.jobType === 'Freelance' && this.profile.freelanceJobPosts <= 0)
      ) {
        this.$store.dispatch('togglePurchaseCredits', {
          active: true,
          creditType: `${job.jobType}`,
          title: 'Purchase Job Credit',
          text: `Sorry! You need to purchase a ${job.jobType} Job Post Credit to reactivate this job.`,
        });
        return false;
      }
      return true;
    },
    editArchivedJob(job) {
      this.reactivateJobPopup = false;
      this.selected = job;
      this.beforeEdit = JSON.parse(JSON.stringify(job));
      this.editCheck = true;
      this.editingJob = true;
    },
    cancelEdit() {
      this.editingJob = false;
      this.editCheck = false;
      this.beforeEdit = null;
      this.selected = null;
    },
    initReactivateJob(job) {
      if (!this.creditCheck(job)) return;
      this.selected = job;
      this.selected.startDate = null;
      this.selected.endDate = null;
      this.reactivateJobPopup = true;
    },
    async reactivateJob(jobData) {
      let job = jobData;
      let date = new Date(); // Now
      date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
      job.expire_at = date;
      job.updated_at = new Date();
      job.expired = false;
      job.uniqueViews = [];
      job.active = true;
      job.isArchived = false;
      job.positionFilled = false;
      if (job.requiredSkills.constructor !== Array) {
        job.requiredSkills = job.requiredSkills.split(', ');
      }
      if (job.filledReason) {
        job.filledReason = '';
      }
      if (job.archivedReason) {
        job.archivedReason = '';
      }
      if (job.otherExplanation) {
        job.otherExplanation = '';
      }
      await this.$store.dispatch('jobsModule/set', job);
      if (jobData.jobType === 'Freelance') {
        this.$store.dispatch('employerModule/postedFreelanceJob', false);
      }
      if (jobData.jobType === 'Permanent') {
        this.$store.dispatch('employerModule/postedPermanentJob', false);
      }
      this.cancelEdit();
      this.deleteArchivedJob(job);
      this.$vs.notify({
        title: 'Success!',
        text: 'Your job has been successfully reactivated. You can find it in the Jobs tab.',
        color: 'success',
      });
    },
    initDelete(job) {
      this.selected = job;
      this.popupActive = true;
    },
    deleteArchivedJob(selected) {
      this.archivedJobs.splice(this.archivedJobs.indexOf(selected), 1);
      this.$store.dispatch('archivedJobsModule/delete', selected.jobId);
      this.popupActive = false;
      this.selected = null;
    },
    myMethod(event) {
      if (event.keyCode === 27) {
        this.editingJob = false;
      }
    },

    openModalBanner() {
      this.modalOpenBanner = !this.modalOpenBanner;
    },

    formatData(data) {
      let formattedData = Object.values(data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = fields[key];
        }

        return obj;
      });
      return formattedData;
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: inherit;
}
.con-vs-dropdown--menu {
  min-width: 140px !important;
}
</style>
